import React from 'react';
import { Calendar } from 'lucide-react';

export const DEMO_URL = 'https://calendar.app.google/wWTmp9suzujfLK9FA';

interface DemoLinkProps {
  children?: React.ReactNode;
  className?: string;
}

const DemoLink: React.FC<DemoLinkProps> = ({ children, className = "" }) => (
  <a
    href={DEMO_URL}
    target="_blank"
    rel="noreferrer"
    className={`m-4 ml-12 mr-12 block group rounded-xl overflow-hidden focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-silvermist-500 ${className}`}
  >
    <div className="flex items-center gap-3 p-4 bg-silvermist-900 rounded-lg border border-silvermist-700 transition-all duration-200 ease-in-out group-hover:border-silvermist-600 group-hover:scale-[1.01]">
      <Calendar className="w-5 h-5 text-white flex-shrink-0 transition-transform duration-200 ease-in-out group-hover:scale-110" />

      <p className="flex-grow text-white font-general-sans text-base">
        {children || "See how our AI agent can give your sales team instant expert knowledge"}
      </p>

      <span className="flex-shrink-0 px-4 py-2 bg-white text-silvermist-900 text-sm font-semibold rounded-md transition-colors duration-200 ease-in-out group-hover:bg-silvermist-50 group-hover:shadow-md">
        Get a Demo
      </span>
    </div>
  </a>
);

export default DemoLink;
