import React, { useEffect, useState } from 'react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { WolfiaLogo } from '@/components/logo/WolfiaLogo';
import { redirectToLoginOrDashboard } from '@/utils/url';
import { DEMO_URL } from '@/components/blog/DemoLink';

const CTA_TEXT_LOGIN = "Log in";
const CTA_TEXT_DEMO = "Schedule a demo";

export const MarketingNav = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <div className={`py-2 md:py-4 sticky top-0 inset-x-0 backdrop-blur-2xl backdrop-saturate-150 z-50 bg-white/75 font-general-sans`}>
      <nav className="max-w-7xl px-4 mx-auto flex justify-between items-center">
        <WolfiaLogo width={32} height={32} />
        <div className="hidden md:flex flex-grow justify-center items-center">
          <NavLinks />
        </div>
        <div className="hidden md:flex items-center gap-3">
          <LoginButton />
          <ScheduleDemoButton />
        </div>
        <button
          type="button"
          onClick={() => setIsMenuOpen((open) => !open)}
          aria-label="toggle menu"
          className="md:hidden text-2xl text-black p-2 -mr-2"
        >
          <Bars3Icon width="1em" height="1em" />
        </button>
        <div
          className={`${
            isMenuOpen ? 'translate-y-0 opacity-100' : '-translate-y-full opacity-0'
          } transition-all duration-300 ease-in-out top-0 right-0 left-0 fixed bg-white p-4 flex flex-col items-center gap-8 drop-shadow-lg md:hidden`}
        >
          <button
            type="button"
            onClick={() => setIsMenuOpen((open) => !open)}
            aria-label="close menu"
            className="absolute top-4 right-4 text-2xl text-black p-2 -mr-2"
          >
            <XMarkIcon width="1em" height="1em" />
          </button>
          <div className="mt-12 w-full flex flex-col items-center gap-6">
            <NavLinks isMobile={true}/>
            <ScheduleDemoButton isMobile={true}/>
            <LoginButton />
          </div>
        </div>
      </nav>
    </div>
  );
};

const LoginButton = () => (
  <Link
    href={redirectToLoginOrDashboard()}
    className="font-general-sans text-center text-base font-medium text-gray-700 py-2 px-5 rounded-full border border-gray-200 bg-gray-50 hover:border-gray-400 hover:bg-gray-100 transition-colors outline-none focus-visible:outline-black outline-offset-2"
  >
    {CTA_TEXT_LOGIN}
  </Link>
);

const ScheduleDemoButton = ({ isMobile = false }) => (
  <Link
    href={DEMO_URL}
    className={`font-general-sans text-center text-base font-semibold text-white py-2 px-5 rounded-full block bg-black hover:bg-gray-800 transition-colors shadow-sm outline-none focus-visible:outline-black outline-offset-2 ${isMobile ? 'w-full max-w-xs' : ''}`}
  >
    {CTA_TEXT_DEMO}
  </Link>
);

const NavLinks = ({ isMobile = false }) => {
  return (
    <ul className={`flex ${isMobile ? 'flex-col items-center' : 'md:flex-row'} gap-6`}>
      <NavLink href="/docs">Docs</NavLink>
      <NavLink href="/blog">Blog</NavLink>
      <NavLink href="/case-studies">Case studies</NavLink>
      <NavLink href="/company">Company</NavLink>
    </ul>
  );
};

const NavLink = ({ href, children }: { href: string; children: React.ReactNode }) => {
  const router = useRouter();
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const isActive = isMounted && (router.pathname === href || (href !== "/" && router.pathname.startsWith(href)));
  const baseClassName = 'block transition-colors p-3 md:p-2 relative outline-none rounded focus-visible:outline-black outline-offset-2';
  const activeStateClassName = isActive
    ? 'text-black font-semibold'
    : 'text-gray-600 hover:text-black focus-visible:text-black';

  return (
    <li>
      <Link
        href={href}
        className={`${baseClassName} ${isMounted ? activeStateClassName : 'text-gray-600'}`}
      >
        <span className="font-general-sans md:text-base text-lg">
          {children}
        </span>
      </Link>
    </li>
  );
};

const FOOTER_NAV_SECTIONS = [
  {
    heading: "Platform",
    links: [
      { href: "/", label: "Home" },
      { href: "/docs", label: "Docs" },
    ],
  },
  {
    heading: "Resources",
    links: [
      { href: "/blog", label: "Blog" },
      { href: "/case-studies", label: "Case studies" },
    ],
  },
  {
    heading: "General", // Renamed from Legal & Info
    links: [
      { href: "/company", label: "Company" },
      { href: "/security", label: "Security" },
      { href: "/terms", label: "Terms of service" },
      { href: "/privacy", label: "Privacy policy" },
    ],
  },
  {
    heading: "Connect",
    links: [
      { href: "https://twitter.com/wolfia_app", label: "Twitter", external: true },
      { href: "https://www.linkedin.com/company/wolfia-app", label: "LinkedIn", external: true },
    ],
  },
];

export const MarketingFooter = () => {
  return (
    <footer className='bg-white font-general-sans border-t border-gray-200'>
      <div className="max-w-7xl py-12 px-4 md:py-16 mx-auto">
        <div className="flex flex-col md:flex-row justify-between items-start gap-10">
          <div className="flex flex-col items-center md:items-start mb-6 md:mb-0">
            <WolfiaLogo width={32} height={32} />
            <p className="mt-3 text-black text-sm">
              © {new Date().getFullYear()} Wolfia. All rights reserved.
            </p>
          </div>
          <div className="grid grid-cols-2 sm:grid-cols-4 gap-8 w-full md:w-auto">
            {FOOTER_NAV_SECTIONS.map((section) => (
              <div key={section.heading}>
                <h3 className="text-sm font-semibold mb-4 text-black">
                  {section.heading}
                </h3>
                <ul className="flex flex-col gap-3">
                  {section.links.map((link) => (
                    <FooterNavLink key={link.href} href={link.href} target={link.external ? "_blank" : undefined}>
                      {link.label}
                    </FooterNavLink>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

const FooterNavLink = ({
  href,
  children,
  target,
}: {
  href: string;
  children: React.ReactNode;
  target?: '_blank';
}) => {
  return (
    <li>
      <Link
        href={href}
        className="block text-sm text-gray-600 hover:text-black hover:underline transition-colors p-1 -m-1 rounded focus-visible:outline-black outline-2 outline-offset-2"
        target={target}
        rel={target === "_blank" ? "noopener noreferrer" : undefined}
      >
        {children}
      </Link>
    </li>
  );
};
